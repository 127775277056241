import React from 'react';
import { useSelector } from 'react-redux';

import { Selectors, useNewDealContext } from '@makemydeal/dr-activities-open-deal';
import { Alert } from '@interstate/components/Alert';
import { ArrowTopRightOnSquareIcon, XCircleIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { StyledLink, StyledLinkLinkContainer } from './SuccessComponent.style';

export interface SuccessComponentProps {
    selectors: Selectors;
}

export const SuccessComponent: React.FC<SuccessComponentProps> = ({ selectors }) => {
    const theme = useInterstateTheme();

    const { onCompleted } = useNewDealContext();

    const customerName = useSelector(selectors.getConsumer)?.fullName ?? '';
    const vehicle = useSelector(selectors.getVehicle);
    const managerViewLink = useSelector(selectors.getManagerViewLink);
    const salesViewLink = useSelector(selectors.getSalesViewLink);

    const successMessage = `${customerName}'s deal on a ${vehicle?.year} ${vehicle?.make} ${vehicle?.model} ${vehicle?.trim} has been saved.`;

    return (
        <>
            <Alert type="success" data-testid="success-new-deal-created-alert" title="Deal Saved Successfully" disableSuccessFade>
                <span>{successMessage}</span>
            </Alert>
            <StyledLinkLinkContainer>
                {managerViewLink && (
                    <StyledLink href={managerViewLink} target="_blank" rel="noreferrer">
                        <ArrowTopRightOnSquareIcon data-testid="card-check-icon" color={theme.tokens.BaseColorBlue700} /> Go To Deal
                        In Manager View
                    </StyledLink>
                )}
                {salesViewLink && (
                    <StyledLink href={salesViewLink} target="_blank" rel="noreferrer">
                        <ArrowTopRightOnSquareIcon data-testid="card-check-icon" color={theme.tokens.BaseColorBlue700} /> Go To Deal
                        In Sales View
                    </StyledLink>
                )}
                {onCompleted && (
                    <StyledLink onClick={onCompleted}>
                        <XCircleIcon data-testid="x-circle-icon" color={theme.tokens.BaseColorBlue700} /> Go Back
                    </StyledLink>
                )}
            </StyledLinkLinkContainer>
        </>
    );
};
