import OktaAuth from '@okta/okta-auth-js';
import React, { ReactNode, createContext, useContext } from 'react';

interface NewDealContextProps {
    getAuthOverride?: () => Promise<string>;
    onCancel?: () => void;
    onCompleted?: () => void;
}

interface NewDealProviderProps extends NewDealContextProps {
    children: ReactNode;
}

const NewDealContext = createContext<NewDealContextProps>({
    getAuthOverride: undefined,
    onCancel: undefined,
    onCompleted: undefined
});

export const useNewDealContext = () => {
    return useContext(NewDealContext);
};

export const NewDealContextProvider: React.FC<NewDealProviderProps> = ({ getAuthOverride, onCancel, onCompleted, children }) => {
    return <NewDealContext.Provider value={{ getAuthOverride, onCancel, onCompleted }}>{children}</NewDealContext.Provider>;
};
