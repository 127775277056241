import styled from 'styled-components';
import { MAX_APP_CONTAINER_WIDTH } from '../../constants';

export const StyledFooterContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    position: sticky;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1001;
`;

export const StyledFooterRow = styled.div`
    border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray300};
    display: flex;
    justify-content: flex-end;
    max-width: ${MAX_APP_CONTAINER_WIDTH};
    margin: 0 auto;
    padding: ${({ theme }) => theme.tokens.BaseSpace40};
    width: 100%;
    gap: ${({ theme }) => theme.tokens.BaseSpace20};
`;

export const StyledFooterCancelContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
