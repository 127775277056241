import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeNewDealModal, selectors } from '../../../store';
import { StyledFooterCancelContainer } from '../Footer.style';
import { LoadingIndicatorWrapper } from './LoadingIndicatorWrapper.style';

import { Button } from '@interstate/components/Button';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { CreateNewDealDialogErrorContent } from './CreateNewDealDialogErrorContent';

export const CreateNewDealDialog: React.FC = () => {
    const dispatch = useDispatch();
    const isCreatingNewDeal = useSelector(selectors.isCreatingNewDeal);
    const isCreateNewDealFailure = useSelector(selectors.isCreateNewDealFailure);
    const errorMessage = useSelector(selectors.getErrorMessage);

    const handleCloseModal = () => dispatch(closeNewDealModal());

    const footerComponent = isCreateNewDealFailure ? (
        <StyledFooterCancelContainer>
            <Button data-testid="create-new-deal-dialog-close-btn" buttonStyle="secondary" onClick={handleCloseModal}>
                Close
            </Button>
        </StyledFooterCancelContainer>
    ) : (
        <LoadingIndicatorWrapper>
            <LoadingIndicator size="small" data-testid="create-new-deal-dialog-loading-indicator" />
        </LoadingIndicatorWrapper>
    );

    const content = isCreateNewDealFailure ? (
        <CreateNewDealDialogErrorContent message={errorMessage} />
    ) : (
        <Typography variant="body-md" data-testid="create-new-deal-loading-content">
            Creating a deal. Please hold on while we save your deal.
        </Typography>
    );

    return (
        <Modal
            show={isCreatingNewDeal || isCreateNewDealFailure}
            size="small"
            data-testid="create-new-deal-dialog"
            header={
                <Typography variant="h3" color="base.color.blue.900">
                    Creating new deal
                </Typography>
            }
            sx={{
                '.ids-modal-fixed-footer-component': {
                    padding: '20px 24px 24px'
                }
            }}
            footer={{ footerComponent }}
        >
            {content}
        </Modal>
    );
};
