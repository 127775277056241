import { MouseEventHandler } from 'react';

import { CheckCircleIcon } from '@interstate/components/Icons/CheckCircleIcon';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { InformationCircleIcon } from '@interstate/components/Icons';
import { Action } from '@interstate/components/Action';
import { Tooltip } from '@interstate/components/Tooltip';

import { StyledActionContent, StyledCardHeader, StyledCardTitle } from './OpenDealCardHeader.style';

export type CardHeaderProps = {
    title: string;
    showIcon?: boolean;
    isComplete?: boolean;
    action?: string;
    onActionClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
    tooltip?: string;
    isNewDeal?: boolean;
};

export const OpenDealCardHeader = ({ title, showIcon, isComplete, action, onActionClick, tooltip, isNewDeal }: CardHeaderProps) => {
    const theme = useInterstateTheme();

    const competedIcon = <CheckCircleIcon data-testid="card-check-icon" color={theme.tokens.BaseColorGreen500} />;
    const tooltipIcon = <InformationCircleIcon data-testid="card-info-icon" color={theme.tokens.BaseColorBlue500} />;

    const icon = isComplete && competedIcon;

    const renderContent = (isNewDeal: boolean | undefined, tooltip: string | undefined) => {
        return (
            <>
                {showIcon && icon}
                {title}
                {tooltip && isNewDeal && (
                    <Tooltip toolTipContent={tooltip} position="top-start" size="lg" className="header-tooltip">
                        {tooltipIcon}
                    </Tooltip>
                )}
            </>
        );
    };

    return (
        <StyledCardHeader className="card-header" data-testid="card-header">
            <StyledCardTitle data-testid="card-title">{renderContent(isNewDeal, tooltip)}</StyledCardTitle>
            {action && (
                <Action data-testid="card-action" onClick={onActionClick}>
                    {<StyledActionContent>{action}</StyledActionContent>}
                </Action>
            )}
        </StyledCardHeader>
    );
};
