// externals
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate/components/Button';

// components
import { CancelDealDialog } from './CancelDealDialog';

// selectors
import { selectors } from '../../../store';
import { useBeforeUnload } from './useBeforeUnload';
import { useNewDealContext } from '../../NewDealModuleContext';

export const CancelDeal: FC = () => {
    const isDealModified = useSelector(selectors.isDealModified);
    const isOpeningDeal = useSelector(selectors.isOpeningDeal);
    const isCreatingNewDeal = useSelector(selectors.isCreatingNewDeal);
    const isNewDeal = useSelector(selectors.isNewDeal);
    const [showDialog, setShowDialog] = useState(false);
    const { onCancel } = useNewDealContext();

    useBeforeUnload(isDealModified && !isOpeningDeal && !isCreatingNewDeal);

    const cancelAction = isNewDeal && onCancel ? onCancel : window.close;

    const handleCancel = () => {
        if (!isDealModified) {
            cancelAction();
        } else {
            setShowDialog(true);
        }
    };

    const handleHide = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button data-testid="footer-cancel-deal-button" buttonStyle="secondary" onClick={handleCancel}>
                Cancel
            </Button>

            <CancelDealDialog show={showDialog} onHide={handleHide} onCancel={cancelAction} />
        </>
    );
};
