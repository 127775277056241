import { Activity } from '@makemydeal/dr-activities-open-deal';
import { useSelector } from 'react-redux';
import { getOpenDealConfigFromState, getOpenDealSelectors } from '../../shells/main/activities/open-deal-dashboard/config';
import { SuccessComponent } from './SuccessComponent';

export const ActivityWrapper: React.FC = () => {
    const config = useSelector(getOpenDealConfigFromState);
    const selectors = getOpenDealSelectors();
    const actionsCreators = {} as any;
    const isNewDealCreatedSuccessfully = useSelector(selectors.isNewDealCreatedSuccessfully);
    return (
        <>
            {isNewDealCreatedSuccessfully ? (
                <SuccessComponent selectors={selectors} />
            ) : (
                <Activity actionCreators={actionsCreators} config={config} baseRoute="/" selectors={selectors} />
            )}
        </>
    );
};
