// libraries
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';

// constants
import {
    COMMON_CONSUMER_NOT_FOUND_MSG,
    DEALER_NOT_ASSOCIATED_MSG,
    DEALER_SUPPORT_REQUEST_FORM,
    DEALERSHIP_NOT_FOUND_MSG
} from '../../../constants';

const errorMessageMap: Record<string, string> = {
    [COMMON_CONSUMER_NOT_FOUND_MSG]: 'An error occurred with when adding a customer. Please get in touch with your support team.',
    [DEALERSHIP_NOT_FOUND_MSG]: 'An error occurred with your dealership selection. Please get in touch with your support team.',
    [DEALER_NOT_ASSOCIATED_MSG]:
        'An error occurred with your Customer Experience selection. Please get in touch with your support team.'
};

export const CreateNewDealDialogErrorContent = ({ message }: { message?: string }) => {
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPORT_REQUEST_FORM);
    };
    const details = message ? (
        <Typography variant="body-md" data-testid="open-deal-error-message">
            Details: {message in errorMessageMap ? errorMessageMap[message] : message}
        </Typography>
    ) : null;

    return (
        <>
            <Alert type="error" sx={{ marginBottom: '24px' }} data-testid="open-deal-error-alert">
                <Typography variant="h6">Failed to create deal</Typography>
                {details}
            </Alert>

            <Grid sx={{ lineHeight: '18px' }}>
                <Typography variant="strong-md">Please contact support:</Typography>
                <Typography variant="body-md">1-888-895-2994 or create a</Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket">
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        support ticket
                    </Typography>
                </Action>
            </Grid>
        </>
    );
};
