import { FC } from 'react';

import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';

export type ModalDialogProps = {
    show: boolean;
    onHide: () => void;
    onCancel?: () => void;
};

export const CancelDealDialog: FC<ModalDialogProps> = ({ show, onHide, onCancel = window.close }) => {
    return (
        <Modal
            show={show}
            size="small"
            data-testid={'open-deal-cancel-dialog'}
            onHide={onHide}
            header={
                <Typography variant="h3" color="base.color.blue.900">
                    Are you sure?
                </Typography>
            }
            footer={{
                options: [
                    {
                        action: () => onHide(),
                        label: 'No'
                    }
                ],
                primary: {
                    action: () => onCancel(),
                    label: 'Yes'
                }
            }}
        >
            <Typography variant="body-md" tag="div">
                By clicking "Yes" any information entered will be discarded.
            </Typography>
        </Modal>
    );
};
