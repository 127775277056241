import { useRef } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import OktaAuth from '@okta/okta-auth-js';

import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { configureStore } from '@makemydeal/dr-shared-store';
import { MemoryRouter } from '@makemydeal/dr-react-18-wrappers';
import { apiConfigHelper, HostEnvironment, OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { setOktaAuthForInterceptors, setupAuthNetworkInterceptsForModule } from '@makemydeal/dr-shared-network';

import { GlobalStyle } from '../globalStyle/GlobalStyle.style';
import { getInitialMiddleware } from '../shells/main/store/initialMiddleware';
import { getRemainingMiddleware } from '../shells/main/store/remainingMiddleware';
import { Bootstrapper } from '../shells/main/components/bootstrapper/Bootstrapper';
import { createInitialReducers } from '../shells/main/reducers/initialReducers';
import { NewDealContextProvider } from '@makemydeal/dr-activities-open-deal';
import { NewDealForm } from './new-deal-form';

export interface NewDealModuleProps {
    commonOrgId: string;
    oktaAuth: OktaAuth;
    environment: HostEnvironment;
    canDesk?: boolean;
    getAuthOverride?: () => Promise<string>;
    onCancel?: () => void;
    onCompleted?: () => void;
}

const state = {};
const allMiddleware = getInitialMiddleware().concat(getRemainingMiddleware());

function initializeNetworkInterceptors(environment: HostEnvironment, oktaAuth: OktaAuth) {
    setupAuthNetworkInterceptsForModule(apiConfigHelper.apiUrlBuilderFromEnvironment(environment, OPEN_DEAL_APP_PREFIX));
    setOktaAuthForInterceptors(oktaAuth);
}

export function NewDealModule({
    oktaAuth,
    environment,
    canDesk,
    commonOrgId,
    getAuthOverride,
    onCancel,
    onCompleted
}: NewDealModuleProps) {
    const storeRef = useRef(configureStore(state, allMiddleware, createInitialReducers(environment)));

    initializeNetworkInterceptors(environment, oktaAuth);

    return (
        storeRef.current && (
            <>
                <GlobalStyle />
                <InterstateThemeProvider
                    themeName="Interstate"
                    applicationName="Open Deal"
                    scopeName="Open Deal"
                    themeRegistries={[interstateThemeRegistry]}
                >
                    <ReduxProvider store={storeRef.current}>
                        <MemoryRouter>
                            <Bootstrapper environment={environment} coOrgId={commonOrgId}>
                                <NewDealContextProvider
                                    getAuthOverride={getAuthOverride}
                                    onCancel={onCancel}
                                    onCompleted={onCompleted}
                                >
                                    <NewDealForm canDesk={canDesk} environment={environment} />
                                </NewDealContextProvider>
                            </Bootstrapper>
                        </MemoryRouter>
                    </ReduxProvider>
                </InterstateThemeProvider>
            </>
        )
    );
}

export default NewDealModule;
