import React, { useEffect } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { StyledBodyContainer } from '../shells/main/routing/routes.style';
import { ActivityWrapper } from './components/ActivityWrapper';
import { HostEnvironment } from '@makemydeal/dr-shared-ui-utils';
import { setCanDesk, setEnvironment, setIsNewDeal } from '@makemydeal/dr-activities-open-deal';
import { useDispatch } from 'react-redux';

export type NewDealFormProps = {
    environment: HostEnvironment;
    canDesk?: boolean;
};

export const NewDealForm: React.FC<NewDealFormProps> = ({ environment, canDesk }) => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setEnvironment(environment));
        dispatch(setCanDesk(canDesk ?? false));
        dispatch(setIsNewDeal(true));
    }, [dispatch, environment]);

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <StyledBodyContainer data-testid="new-deal-module-form-container">
                    <ActivityWrapper />
                </StyledBodyContainer>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
